import React, { useState, useRef } from "react";
import { Button, Space, Table, Checkbox, message } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CSVLink } from "react-csv"; // Import CSVLink
import axios from "axios";

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [, drop] = useDrop({
    accept: type,
    hover: (item) => {
      if (item.index !== index) {
        moveRow(item.index, index);
        item.index = index;
      }
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return (
    <tr
      ref={ref}
      className={className}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const FilesTable = ({
  filesData,
  handleDelete,
  handleEdit,
  rowSelection,
  setFilesData,
  handleSelectAll,
  isSelectAll,
  SERVER_API,
}) => {
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const fileRefData = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleCsvUpload(file);
    }
    fileRefData.current.value = null;
  };

  const handleCsvUpload = async (file) => {
    if (!file) {
      message.error("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${SERVER_API}/csvupload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("File uploaded successfully!");
    } catch (error) {
      console.error(error);
      message.error("File upload failed.");
    }
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const pageIndex = (currentPage - 1) * pageSize;
    const dragRow = filesData[pageIndex + dragIndex];
    const newData = [...filesData];
    newData.splice(pageIndex + dragIndex, 1);
    newData.splice(pageIndex + hoverIndex, 0, dragRow);
    setFilesData(newData);
  };

  const columns = [
    {
      title: <Checkbox checked={isSelectAll} onChange={handleSelectAll} />,
      dataIndex: "checkbox",
      key: "checkbox",
      width: 50,
      render: (_, record) => (
        <Checkbox
          checked={rowSelection.selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const selectedKeys = [...rowSelection.selectedRowKeys];
            if (e.target.checked) {
              selectedKeys.push(record.key);
            } else {
              const index = selectedKeys.indexOf(record.key);
              if (index > -1) {
                selectedKeys.splice(index, 1);
              }
            }
            rowSelection.onChange(
              selectedKeys,
              selectedKeys.map((key) =>
                filesData.find((item) => item.key === key)
              )
            );
          }}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 80,
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "ISO",
      dataIndex: "ISO",
      key: "ISO",
      width: 100,
      sorter: (a, b) => a.ISO.localeCompare(b.ISO),
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      sorter: (a, b) => a.question.localeCompare(b.question),
    },
    {
      title: "Conformance",
      dataIndex: "conformance",
      key: "conformance",
      width: 120,
      sorter: (a, b) => a.conformance.localeCompare(b.conformance),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      sorter: (a, b) => a.comments.localeCompare(b.comments),
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <Space size="middle">
          <Button type="primary" danger onClick={() => handleDelete(item)}>
            Delete
          </Button>
          <Button onClick={() => handleEdit(item)}>Edit</Button>
        </Space>
      ),
    },
  ];

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex justify-start gap-4 py-2">
        <input
          type="file"
          ref={fileRefData}
          onChange={handleFileChange}
          className="hidden"
          accept=".csv"
        />
        <Button
          type="primary"
          onClick={() => {
            if (fileRefData.current) {
              fileRefData.current.click();
            }
          }}
        >
          Import to CSV
        </Button>

        <CSVLink data={filesData} filename={"questions.csv"}>
          <Button type="primary">Export to CSV</Button>
        </CSVLink>
      </div>
      <Table
        dataSource={filesData}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSizeOptions: ["5", "20", "30"],
          showSizeChanger: true,
          defaultPageSize: 5,
          current: currentPage,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            if (pageSize === "All") {
              setPageSize(filesData.length);
            } else {
              setPageSize(pageSize);
            }
          },
        }}
        scroll={{ y: 600 }}
        components={{
          body: {
            row: DraggableBodyRow,
          },
        }}
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
    </DndProvider>
  );
};

export default FilesTable;
