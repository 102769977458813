// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import ChatWithAI from "./components/ChatWithAI";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import ProtectedRoute from "./components/ProtectedRoute";
import useAuth from "./hooks/useAuth";
import useSessionTimeout from "./hooks/useSessionTimeout";

const SERVER_API = "https://app.isoauditpro.com/api";

function App() {
  const { isAuthenticated, logout } = useAuth();
  useSessionTimeout(isAuthenticated, logout);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate replace to="/home" />
            ) : (
              <SignIn />
            )
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Home SERVER_API={SERVER_API} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ChatWithAI SERVER_API={SERVER_API} />
            </ProtectedRoute>
          }
        />
        {/* Redirect any unknown routes to SignIn */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;