import React, { useState, useEffect, useRef } from "react";
import { Input, Button, List, Layout, Spin } from "antd";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { TextArea } = Input;

const ChatWithAI = ({ SERVER_API, openNotificationWithIcon }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const handleSendChat = async (messages) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${SERVER_API}/chatai`, { messages });
      if (response.status === 200 && response.data.success) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "assistant", text: response.data.data },
        ]);
      } else {
        openNotificationWithIcon(
          "error",
          response.data.message || "Error fetching AI response"
        );
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      const newMessages = [...messages, { sender: "user", text: input }];
      setMessages(newMessages);
      setInput("");
      handleSendChat(newMessages);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]);

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('Text copied to clipboard!');
      },
      () => {
        message.error('Failed to copy text');
      }
    );
  };

  return (
    <Layout>
      <Content className="chat-container">
        <div className="chat-messages" ref={messagesEndRef}>
          <List
            dataSource={messages}
            renderItem={(item) => (
              <List.Item
                className={
                  item.sender === "user" ? "user-message" : "assistant-message"
                }
                actions={
                  item.sender === "assistant" ? [
                    <Tooltip title="Copy">
                      <CopyOutlined onClick={() => handleCopyText(item.text)} />
                    </Tooltip>
                  ] : []
                }
              >
                <div>
                  <ReactMarkdown>{item.text}</ReactMarkdown>
                </div>
              </List.Item>
            )}
          />
        </div>
        {isLoading && (
          <div className="chat-loading">
            <Spin tip="AI is thinking..." />
          </div>
        )}
        <div className="chat-input">
          <TextArea
            rows={2}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onPressEnter={handleSend}
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSend}
            loading={isLoading}
          >
            Send
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

export default ChatWithAI;
