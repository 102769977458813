// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from "react";
import { notification } from "antd";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    return storedAuth === "true";
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: type.charAt(0).toUpperCase() + type.slice(1),
      description: msg,
      placement: "topRight",
    });
  };

  const login = (credentials) => {
    // Replace with actual authentication logic
    if (credentials.username === "sg@isoauditpro.com" && credentials.password === "isoauditpro") {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true");
      openNotificationWithIcon("success", "Successfully signed in!");
      return true;
    } else {
      openNotificationWithIcon("error", "Invalid username or password.");
      return false;
    }
  };

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    openNotificationWithIcon("info", "You have been logged out.");
    window.location.href = "/";
  }, [api]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {contextHolder}
      {children}
    </AuthContext.Provider>
  );
};