// src/pages/SignIn.js
import React, { useContext } from "react";
import { Form, Input, Button, Card, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import "./SignIn.css";

const { Title } = Typography;

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const onFinish = (values) => {
    const success = login(values);
    if (success) {
      navigate("/home");
    }
  };

  return (
    <div className="signin-container">
      <Card className="signin-card" bordered={false}>
        <Title level={2} className="signin-title">
          Welcome Back
        </Title>
        <Form
          name="signin_form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SignIn;