// src/hooks/useAuth.js
import { useContext, useEffect, useRef, useCallback } from "react";
import { AuthContext } from "../contexts/AuthContext";

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes

const useAuth = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const logoutTimerRef = useRef(null);

  const resetLogoutTimer = useCallback(() => {
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = setTimeout(() => {
      logout();
    }, SESSION_TIMEOUT);
  }, [logout]);

  useEffect(() => {
    if (isAuthenticated) {
      const events = ["mousemove", "mousedown", "keypress", "touchstart", "scroll"];
      const handleActivity = () => {
        resetLogoutTimer();
      };
      events.forEach((event) => window.addEventListener(event, handleActivity));
      resetLogoutTimer();
      return () => {
        events.forEach((event) => window.removeEventListener(event, handleActivity));
        if (logoutTimerRef.current) {
          clearTimeout(logoutTimerRef.current);
        }
      };
    }
  }, [isAuthenticated, resetLogoutTimer]);

  return { isAuthenticated };
};

export default useAuth;