// src/hooks/useSessionTimeout.js
import { useEffect, useRef, useCallback } from "react";

const SESSION_TIMEOUT = 24 * 60 * 60 * 1000;

const useSessionTimeout = (isAuthenticated, logout) => {
  const logoutTimerRef = useRef(null);
  const checkIntervalRef = useRef(null);

  const setSessionEnd = useCallback(() => {
    const sessionEnd = Date.now() + SESSION_TIMEOUT;
    localStorage.setItem("sessionEnd", sessionEnd);
  }, []);

  const resetLogoutTimer = useCallback(() => {
    setSessionEnd();
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = setTimeout(() => {
      logout();
    }, SESSION_TIMEOUT);
  }, [setSessionEnd, logout]);

  useEffect(() => {
    if (isAuthenticated) {
      const events = ["mousemove", "mousedown", "keypress", "touchstart", "scroll"];

      const handleActivity = () => {
        resetLogoutTimer();
      };

      events.forEach((event) => window.addEventListener(event, handleActivity));

      resetLogoutTimer();

      checkIntervalRef.current = setInterval(() => {
        const storedSessionEnd = localStorage.getItem("sessionEnd");
        if (storedSessionEnd && Date.now() > parseInt(storedSessionEnd, 10)) {
          logout();
        }
      }, 1000 * 60); // Every minute

      return () => {
        events.forEach((event) => window.removeEventListener(event, handleActivity));
        if (logoutTimerRef.current) {
          clearTimeout(logoutTimerRef.current);
        }
        if (checkIntervalRef.current) {
          clearInterval(checkIntervalRef.current);
        }
      };
    }
  }, [isAuthenticated, resetLogoutTimer, logout]);

  useEffect(() => {
    if (isAuthenticated) {
      const storedSessionEnd = localStorage.getItem("sessionEnd");
      if (storedSessionEnd && Date.now() > parseInt(storedSessionEnd, 10)) {
        logout();
      }
    }
  }, [isAuthenticated, logout]);
};

export default useSessionTimeout;