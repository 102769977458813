import React from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import axios from "axios";

const { Dragger } = Upload;

const FileUploader = ({
  onFileUploaded,
  type,
  openNotificationWithIcon,
  SERVER_API,
}) => {
  const handleUpload = async ({ file, onSuccess, onError, onProgress }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    try {
      const response = await axios.post(`${SERVER_API}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          onProgress({ percent });
        },
      });
      console.log(response)
      onFileUploaded(response.data);
      openNotificationWithIcon("success", response.data.message);
    } catch (error) {
      openNotificationWithIcon("error", error.response.data.detail || error.message);
    }
  };

  const props = {
    name: "file",
    multiple: true,
    customRequest: handleUpload,
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      console.log(info)
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    // <Upload {...props}>
    //   <Button className="flex items-center" icon={<UploadOutlined />}>
    //     {type === 'learning' ? "Upload Learning Docs" : "Upload Client Docs"}
    //   </Button>
    // </Upload>
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text !text-white">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};

export default FileUploader;
