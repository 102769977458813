import React, { useEffect, useState, useRef, useMemo } from "react";

import {
  Layout,
  notification,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  Select,
} from "antd";

import FileUploader from "../components/FileUploader";
import FilesTable from "../components/FilesTable";
import axios from "axios";
import "../App.css";
import { DeleteOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Sider, Content } = Layout;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const Home = ({ SERVER_API }) => {
  const [filesData, setFilesData] = useState([]);
  const [question, setQuestion] = useState([]);
  const [chooseQuestion, setChooseQuestion] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [form] = Form.useForm();
  const [selectedRowsState, setSelectedRowsState] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedValue, setSelectedValue] = useState(["All"]);

  const processingRef = useRef(isProcessing);

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type.charAt(0).toUpperCase() + type.slice(1),
      description: msg,
      placement: "topRight", // Optional
    });
  };

  useEffect(() => {
    getFiles();
    getQuestions();
  }, []);

  useEffect(() => {
    processingRef.current = isProcessing;
    if (isProcessing) {
      startProcessing();
    }
  }, [isProcessing]);

  const getFiles = async () => {
    try {
      const response = await axios.get(`${SERVER_API}/get_files`);
      if (response.status === 200) {
        setFilesData(response.data.data);
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    }
  };

  const getQuestions = async () => {
    try {
      const response = await axios.get(`${SERVER_API}/get_questions`);
      if (response.status === 200) {
        setQuestion(
          response.data.data.map((item, index) => ({ ...item, key: index }))
        );
        setSelectedRowKeys([]);
        setSelectedRowsState([]);
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    }
  };

  const handleFileUploaded = async () => {
    await getFiles();
  };

  const handleFileRemove = async (id) => {
    try {
      const response = await axios.post(`${SERVER_API}/remove_file`, { id });
      if (response.status === 200) {
        openNotificationWithIcon("success", response.data.message);
      } else {
        openNotificationWithIcon("error", response.data.message);
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    } finally {
      getFiles();
    }
  };

  const handleDelete = async (item) => {
    try {
      const response = await axios.post(`${SERVER_API}/delete_question`, {
        question: item,
      });
      if (response.status === 200) {
        openNotificationWithIcon("success", "Success Removed");
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    } finally {
      getQuestions();
    }
  };

  const handleEdit = (item) => {
    setEditModal(true);
    setEditItem(item);
    form.setFieldsValue({
      ISO: item.ISO,
      question: item.question,
    });
    setOpen(true);
  };

  const FileListSection = ({
    filesData,
    type,
    onFileUploaded,
    handleFileRemove,
  }) => (
    <div className="mb-4 h-full flex flex-col">
      <div className="mb-2 flex justify-center">
        <FileUploader
          SERVER_API={SERVER_API}
          onFileUploaded={onFileUploaded}
          type={type}
          openNotificationWithIcon={openNotificationWithIcon}
        />
      </div>
      {filesData.filter((file) => file.type === type).length && (
        <div className="p-2 bg-white rounded-md shadow flex-1 overflow-y-auto">
          {filesData
            .filter((file) => file.type === type)
            .map((file, id) => (
              <div className="flex gap-2 justify-between" key={id}>
                <div className="overflow-x-auto hide-scrollbar">
                  <div className="whitespace-nowrap py-1">
                    {file.orignialName}
                  </div>
                </div>
                <DeleteOutlined
                  onClick={() => handleFileRemove(file.id)}
                  className="text-red-600"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );

  const onFinish = async (values) => {
    try {
      setConfirmLoading(true);
      if (editModal) {
        const data = values;
        data["id"] = editItem.id;
        const response = await axios.post(`${SERVER_API}/edit_question`, {
          question: data,
        });
        if (response.status === 200) {
          openNotificationWithIcon("success", "Edit Question");
        }
      } else {
        const response = await axios.post(`${SERVER_API}/add_question`, {
          question: values,
        });
        if (response.status === 200) {
          openNotificationWithIcon("success", "Add Question Inserted");
        }
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    } finally {
      setOpen(false);
      onReset();
      getQuestions();
      setConfirmLoading(false);
      setEditModal(false);
      setEditItem(null);
    }
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRowsState(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const handleSelectAll = () => {
    if (selectedRowKeys.length === chooseQuestion.length) {
      setSelectedRowKeys([]);
      setSelectedRowsState([]);
    } else {
      const allKeys = chooseQuestion.map((item) => item.key);
      setSelectedRowKeys(allKeys);
      setSelectedRowsState(chooseQuestion);
    }
  };

  const startProcessing = async () => {
    setStatus("Processing...");
    setConfirmLoading(true);

    for (let i = 0; i < selectedRowsState.length; i++) {
      if (!processingRef.current) break;
      await handleCheckItem(selectedRowsState[i]);
      setStatus(`Processing item ${i + 1} of ${selectedRowsState.length}`);
    }

    setConfirmLoading(false);
    getQuestions();
    setStatus("Processing completed");
    setIsProcessing(false);
  };

  const handleStart = () => {
    if (selectedRowsState.length) {
      setIsProcessing(true);
    } else {
      openNotificationWithIcon("error", "There is not any selected data");
      setStatus("");
    }
  };

  const handleStop = () => {
    setIsProcessing(false);
    setStatus("Processing stopped");
  };

  const handleCheckItem = async (item) => {
    try {
      const response = await axios.post(`${SERVER_API}/start`, {
        question: item,
      });
      if (response.status === 200) {
        openNotificationWithIcon("success", `${item.ISO} is success`);
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    }
  };

  const handleReset = async () => {
    try {
      const response = await axios.put(`${SERVER_API}/init_questions`);
      if (response.status === 200) {
        openNotificationWithIcon("success", "Reset Questions");
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    } finally {
      getQuestions();
    }
  };

  const onSearch = async (value, _e, info) => {
    try {
      const response = await axios.post(`${SERVER_API}/search_question`, {
        search: value,
      });
      if (response.status === 200) {
        setQuestion(
          response.data.data.map((item, index) => ({ ...item, key: index }))
        );
      }
    } catch (error) {
      openNotificationWithIcon("error", error.message);
    }
  };

  const groupedQuestions = useMemo(() => {
    const types = question.map((question) => question.type);
    return ["All", ...new Set(types)];
  }, [question]);

  useEffect(() => {
    if (selectedValue.includes("All")) {
      setChooseQuestion(question);
    } else {
      const questions = question.filter((item) =>
        selectedValue.includes(item.type)
      );
      setChooseQuestion(questions);
    }
  }, [question]);

  const handleItemType = (value) => {
    setSelectedValue(value);
    if (value.includes("All")) {
      setChooseQuestion(question);
    } else {
      const questions = question.filter((item) => value.includes(item.type));
      setChooseQuestion(questions);
    }
  };

  return (
    <Layout>
      {contextHolder}
      <Sider width={300} className="bg-gray-200 p-4 h-screen">
        <FileListSection
          filesData={filesData}
          type="custom"
          onFileUploaded={handleFileUploaded}
          handleFileRemove={handleFileRemove}
        />
      </Sider>
      <Layout>
        <Content className="px-4 bg-white">
          <div className="flex p-2 justify-between">
            <Button type="primary" onClick={handleReset}>
              Reset
            </Button>
            <Search
              placeholder="input search text"
              allowClear
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
            <Button onClick={() => setOpen(true)}>Add</Button>
          </div>
          {status && (
            <div className="my-2">
              <Spin spinning={isProcessing} /> {status}
            </div>
          )}
          <div className="flex justify-between">
            <Select
              mode="multiple"
              style={{ width: "50%" }}
              placeholder="Select a question type"
              value={selectedValue}
              onChange={(value) => handleItemType(value)}
            >
              {groupedQuestions.map((type, id) => (
                <Option value={type} key={id}>
                  {type}
                </Option>
              ))}
            </Select>
            <Button
              onClick={() => {
                const chatUrl = window.location.origin + "/chat";
                window.open(chatUrl, "_blank");
              }}
              type="primary"
            >
              ChatWithAI
            </Button>
          </div>
          <FilesTable
            SERVER_API={SERVER_API}
            filesData={chooseQuestion}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            loading={confirmLoading}
            rowSelection={rowSelection}
            setFilesData={setChooseQuestion}
            handleSelectAll={handleSelectAll}
            isSelectAll={selectedRowKeys.length === question.length}
          />
          <div className="flex justify-start gap-2">
            <Button
              type="primary"
              onClick={handleStart}
              disabled={isProcessing}
            >
              Start
            </Button>
            <Button
              type="default"
              onClick={handleStop}
              disabled={!isProcessing}
            >
              Stop
            </Button>
          </div>
        </Content>
      </Layout>
      <Modal
        title={editModal ? "Edit Question" : "Add Question"}
        open={open}
        confirmLoading={confirmLoading}
        onOk={() => form.submit()}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Form
          form={form}
          {...formItemLayout}
          name="control-hooks"
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please input Type" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ISO"
            label="ISO"
            rules={[{ required: true, message: "Please input ISO element" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please input question" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default Home;
